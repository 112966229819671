<template>
    <div id="mains" style="min-height:100vh">
        <a-tabs type="card" tabPosition="left" v-if="groups.length > 0" :animated="false" @change="setGroupId"
            v-model="groupId">
            <a-tab-pane v-for="(item, index) in groups" :tab="item.name" :key="item.code">
                <a-form-model ref="form" label-position="left" :label-width="120">
                    <a-row :gutter="8" v-for="(row, k) in list" :key="k">
                        <a-col :span="row.length > 0 && row.length <= 24 ? row.length : 10">
                            <a-form-model-item :label="row.title" :label-width="200" :extra="row.remark">
                                <template v-if="row.type == 'input'">
                                    <a-input v-model="row.value" :placeholder="'请输入' + row.title" />
                                </template>
                                <template v-if="row.type == 'password'">
                                    <a-input type="password" v-model="row.value" :placeholder="'请输入' + row.title" />
                                </template>
                                <template v-if="row.type == 'textarea'">
                                    <a-input type="textarea" v-model="row.value" :placeholder="'请输入' + row.title" />
                                </template>
                                <template v-if="row.type == 'time'">
                                    <TimePicker format="HH:mm" type="time" :placeholder="'请选择' + row.title"
                                        v-model="row.value"></TimePicker>
                                </template>
                                <template v-if="row.type == 'radio'">
                                    <a-radio-group button-style="solid" v-model="row.value">
                                        <a-radio label="1">开启</a-radio>
                                        <a-radio label="0">关闭</a-radio>
                                    </a-radio-group>
                                </template>
                                <template v-if="row.type == 'switch'">
                                    <Switch v-model="row.value" true-value="1" false-value="0">
                                        <span slot="open">开</span>
                                        <span slot="close">关</span>
                                    </Switch>
                                </template>
                                <template v-if="row.type == 'logo'">
                                    <up-img tipmsg="上传LOGO" :param="{ index: index, key: k, row: row }" :thumb="row.value"
                                        @success="upSuccess"></up-img>
                                </template>
                                <template v-if="row.type == 'image'">
                                    <up-img tipmsg="上传图片" :param="{ index: index, key: k, row: row }" :thumb="row.value"
                                        @success="upSuccess"></up-img>
                                </template>
                                <template v-if="row.type == 'upload_video'">
                                    <up-input :param="{ key: k, row: row }" :thumb="row.value" accept="video/mp4,video/3gp"
                                        @success="upSuccess"></up-input>
                                </template>
                                <template v-if="row.type == 'editor'">
                                    <editor v-model="row.value"></editor>
                                </template>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
                <div class="demo-drawer-footer" v-if="item.configs.length > 0">
                    <a-button type="primary" @click="doSubmit">确认保存</a-button>
                </div>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import upImg from "../../../components/common/upImg";
import upInput from "../../../components/common/upInput";
export default {
    components: { upImg, upInput },
    data() {
        return {
            groupId: "",
            groups: [],
            list: []
        }
    },
    mounted() {
        this.getConfig();
    },
    methods: {
        getConfig() {
            this.http.get("/platform/config/list").then(ret => {
                console.log(ret)
                this.groups = ret.data;
                this.setGroupId(ret.data[0].code);
            })
        },
        //保存配置
        doSubmit() {
            let jsonData = [];
            this.groups.forEach(x => {
                if (this.groupId == x.code) {
                    jsonData = JSON.parse(JSON.stringify(x.configs));
                    jsonData.forEach(row => {
                        if (row.type == 'timerange' && typeof row.value == 'object') {
                            row.value = row.value.join("-");
                        }
                    })
                }
            })
            this.utils.showSpin();
            this.http.post("/platform/config/save", jsonData).then(ret => {
                if (ret.code == 200) {
                    this.loading = false;
                    this.utils.success('操作成功！');
                } else {
                    this.utils.error(ret.message);
                }
            }).catch(err => {
                this.loading = false;
            })
        },
        //选择分组
        setGroupId(e) {
            this.list = [];
            this.groups.map((x, i) => {
                if (x.code == e) {
                    this.list = x.configs;
                }
            })
            this.groupId = e;
        },
        upSuccess(res, param) {
            let k = param.key;
            let index = param.index;
            let row = param.row;
            row.value = res;
            let group = this.groups[index];
            group.configs.splice(k, 1, row);
            // console.log(group);
            this.groups.splice(index, 1, group);
            // this.list.splice(k, 1, row);
        }
    }
}
</script>

<style scoped></style>
